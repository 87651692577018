import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'gatsby'
import cn from 'classnames'
import { useQueryParam, NumberParam } from 'use-query-params'

import { withMainLayout } from 'components/layouts'
import { ReviewCard } from 'components/reviews'
import { Pagination } from 'components/common'
import css from './style.module.scss'
import { Button } from 'components/ui'
import { fetchReviews } from 'components/reviews/actions'
import SEO from 'components/seo'

function ReviewsPage({
  fetchReviews,
}) {
  const [stateUrlPage, setUrlPage] = useQueryParam('page', NumberParam)
  const [stateCurrentPage, setCurrentPage] = useState(stateUrlPage || 1)
  const [stateReviews, setReviews] = useState([])
  const [statePages, setPages] = useState(0)
  const [stateCount, setCount] = useState(0)
  const [stateQuery, setQuery] = useState(`/review/?page=${stateUrlPage || 1}`)
  
  useEffect(() => {
    fetchReviews(stateQuery)
      .then(result => {
        setCurrentPage(stateUrlPage || 1)
        setReviews(result.results)
        setPages(result.pages)
        setCount(result.count)
      })
  }, [stateUrlPage, /**/fetchReviews, /**/stateQuery])

  function handlePageChange(data) {
    setQuery(`/review/?page=${data.selected + 1}`)
    setUrlPage(data.selected + 1)
  }

  return(
    <div className='container'>
      <SEO
        title='Отзывы'
      />
      <div className={css.reviewsPage}>
        <div className={css.head}>
          <Link
            to='/'
            className={cn('linkBack caption primary-color', css.back)}
          >
            Вернуться на Главную
          </Link>
          <div className={css.titleBlock}>
            <h2 className={css.title}>
              Отзывы
              {
                stateCount > 0 &&
                  <span className={css.count}>
                    { stateCount }
                  </span>
              }
            </h2>
            <Link to='/make-review' className={css.button}>
              <Button
                blue
                text='Оставить отзыв'
              />
            </Link>
          </div>
        </div>
        <div className={css.cards}>
          {
            stateReviews.map((item, index) => (
              <ReviewCard
                item={item}
                key={index}
                withSeparator
              />
            ))
          }
        </div>
        {
          statePages > 1 &&
            <div className={css.pagination}>
              <Pagination
                pageCount={statePages}
                pageRangeDisplayed={3}
                onPageChange={handlePageChange}
                initialPage={stateCurrentPage - 1}
              />
            </div>
        }
      </div>
    </div>
  )
}

export default connect(
  null,
  dispatch => ({
    fetchReviews: bindActionCreators(fetchReviews, dispatch),
  })
)(withMainLayout(ReviewsPage))